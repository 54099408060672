import React, { useState, useEffect, useContext, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";
import { Input } from "."
import { useQuery } from '@apollo/client';
import { GET_SYS_ROLES_LIST } from '../../../apollo/queries';
import {
    CheckBoxComponent,
    RadioButtonComponent,
    ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import { useNavigate } from 'react-router-dom'
import { GlobalTheme } from '../../../context/GlobalTheme'
import axios from 'axios'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'

export const UsersListEdit = () => {
    const toastRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('user'))
    const selectedTenant = JSON.parse(localStorage.getItem('selectedTenant'))
    const navigate = useNavigate();
    const globalTheme = useContext(GlobalTheme)
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();
    const client = axios.create({
        baseURL: 'https://authgw-syn-baseplatform-uat.dev.syncrasy.cloud',
        headers: {
            Authorization: `Bearer ${user.access_token}`,
        },
    });

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const { loading, error, data } = useQuery(GET_SYS_ROLES_LIST, {
        variables: { v_tenants_guid: selectedTenant},
    });

    const tenantsListForm = {
        username: {
            label: "Username",
            type: "text",
            placeholder: "Enter your username",
            defaultValue: "",
            rules: {
                required: true,
            },
        },
        password: {
            label: "Password",
            type: "text",
            placeholder: "Enter your password",
            defaultValue: "",
            rules: {
                required: true,
            },
        },
        role_uid: {
            label: "Role",
            type: "dropdown",
            options: [], // We'll update this with data from the GraphQL query
            defaultValue: "",
            rules: {
                required: true,
            },
        },
    };


    if (!loading && !error) {
        tenantsListForm.role_uid.options = data.dal_sys_roles_list_get;
    }

    const formInputs = Object.keys(tenantsListForm).map((e) => {
        const { rules, defaultValue, label } = tenantsListForm[e];

        return (
            <section key={e}>
                <label>{label}</label>
                <Controller
                    name={e}
                    control={control}
                    rules={rules}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                        <div>
                            <Input
                                value={field.value}
                                onChange={field.onChange}
                                {...tenantsListForm[e]}
                            />
                        </div>
                    )}
                />
                {/* {errors[e] && <Error>This field is required</Error>} */}
            </section>
        );
    });

    const onSubmit = async (formData) => {
        formData.locale = 'en'
        formData.tenant_uid = selectedTenant
        formData.user_secret_use = false
        formData.user_secret = '123'
        formData.key = "467w2435256"
        try {
            const response = await client.post('/superadmin/user/create_in_tenant', formData);
            if(response) {
                showToast("User created!", "success")
                return setTimeout(() => { navigate('/manage-tenants') }, 1000);
            }
            
        }
        catch(error) {
            console.log(error.message)
            showToast(`${error.message}`, "danger")
        }
    };

    return (
        <>
            <ToastComponent ref={toastRef} />
            <div className="wrapper" id="forms">
                <div style={{ display: 'flex', backgroundColor: globalTheme[globalTheme.activeTheme]?.headerBackground, padding: '0 10px', alignItems: 'center', height: '40px', fontSize: '14px', }}>
                    Add Users List
                </div>
                <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '24px 32px', background: '#fff', overflow: 'auto' }}>
                    {/* render the form inputs */}
                    {formInputs}
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <ButtonComponent type="submit" cssClass="e-success" style={{ width: '150px' }}>
                            Save
                        </ButtonComponent>
                        <ButtonComponent cssClass="e-primary" onClick={() => navigate('/manage-tenants')} style={{ width: '150px', marginLeft: '20px' }}>
                            Cancel
                        </ButtonComponent>
                    </div>
                </form>
            </div>
        </>


    )
}
