import React, { useRef, useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { useApolloClient } from '@apollo/react-hooks';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import TreeViewPopoverHeader from './treeViewPopoverHeader'
import { MANAGE_REGIONS_POLYGONS_TREE_VIEW_UPDATE, BASE_ADD_REGIONS_AND_REGIONS_LINKS } from '../../../apollo/tasks';
import showTaskStatus from '../../../utils/taskStatusUtils'



const TreeViewPopover = () => {
  const treeRef = useRef(null);
  const toastRef = useRef(null);
  const client = useApolloClient();
  const dispatch = useDispatch();

  const drawnPolygonLatLngs = useSelector(state => state.treeViewReducer.drawnPolygonLatLngs);
  const drawnPolygonName = useSelector(state => state.treeViewReducer.drawnPolygonName);
  const isTreeViewSaveButtonClicked = useSelector(state => state.treeViewReducer.isTreeViewSaveButtonClicked);
  const isTreeViewToggled = useSelector(state => state.treeViewReducer.isTreeViewToggled);
  const regionsGroupsGuidValue = useSelector(state => state.treeViewReducer.regionsGroupsGuidValue);

  const [treeDataObject, setTreeDataObject] = useState(null);
  const [ownersValue, setOwnersValue] = useState(null);
  const [treeData, setTreeData] = useState([
    {
      instance_guid: '93f0d2ed-ada3-449d-a9c2-c22a38b389fb',
      instance_name: 'World',
      type: 'Region',
      regions_groups_regions_guid: "93f0d2ed-ada3-449d-a9c2-c22a38b389fb",
      is_checked: false,
      expanded: true,
    },
  ]);
  const [treeViewNodeState, setTreeViewNodeState] = useState({
    nodeLevel: null,
    selectedNodeId: null,
    isSelectedNodeRegion: false,
    isSelectedNodeType: false,
  })

  const { nodeLevel, selectedNodeId, isSelectedNodeRegion, isSelectedNodeType } = treeViewNodeState;

  const findObjectById = (arr, instance_guid) => {
    for (const obj of arr) {
      if (obj.regions_groups_regions_guid === instance_guid) {
        return obj;
      }
      if (obj.child) {
        const foundChild = findObjectById(obj.child, instance_guid);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return null;
  }

  const handleNodeClick = (args) => {
    const treeViewData = treeRef.current.treeData;
    const foundObject = findObjectById(treeViewData, args.node.dataset.uid);
    if (foundObject.type === 'Region') {
      setTreeDataObject(foundObject)
      setTreeViewNodeState({
        ...treeViewNodeState,
        nodeLevel: args.node.ariaLevel,
        selectedNodeId: args.node.dataset.uid,
        isSelectedNodeRegion: true,
        isSelectedNodeType: false,
      });
    }
    else {
      setTreeDataObject(null)
      setTreeViewNodeState({
        ...treeViewNodeState,
        nodeLevel: args.node.ariaLevel,
        selectedNodeId: args.node.dataset.uid,
        isSelectedNodeRegion: false,
        isSelectedNodeType: true,
      });
    }

  };


  const handleNodesCheck = (args) => {
    const treeViewData = treeRef.current.treeData
    const checkedObject = args.data[0]
    let checkboxClicked = checkedObject.isChecked
    if (checkboxClicked === "false") {
      checkboxClicked = false
    }
    else {
      checkboxClicked = true
    }
    const index = treeViewData.findIndex(obj => obj.regions_groups_regions_guid === checkedObject.id);

    if (index !== -1) {
      treeViewData[index] = {
        ...treeViewData[index],
        is_checked: checkboxClicked
      };
    }
    setTreeData(treeViewData)
  }

  const findFirstAndSecondParent = (jsonData, instanceName) => {
    const instance = jsonData.find(obj => obj.instance_name === instanceName);

    if (!instance) {
      return null;
    }

    const firstParent = jsonData.find(obj => obj.regions_groups_regions_guid === (instance.parent_regions_groups_regions_guid === null ? "93f0d2ed-ada3-449d-a9c2-c22a38b389fb" : instance.parent_regions_groups_regions_guid));

    if (!firstParent) {
      return null;
    }

    let secondParent = jsonData.find(obj => obj.regions_groups_regions_guid === firstParent.parent_regions_groups_regions_guid);
    if (!secondParent) {
      secondParent = null
    }

    return { firstParent, secondParent }
  }

  const manageRegionsPolygonsTreeViewUpdate = async () => {
    let treeDataJSON = JSON.stringify(treeData)
    treeDataJSON = treeDataJSON
    const { data } = await client.query({
      query: MANAGE_REGIONS_POLYGONS_TREE_VIEW_UPDATE, variables: {
        v_tree_view_json: treeDataJSON,
        v_regions_groups_guid: regionsGroupsGuidValue,
        v_sys_owners_guid: ownersValue,
      }
    })
    const runTaskStatusFunc = async (client, data, toastRef, counter = 0) => {
      if (counter >= 10) {
        return; // Exit recursion if counter reaches 10
      }
      let isTaskSucceed = await showTaskStatus(client, data, toastRef);
      if (isTaskSucceed === 'success') {
        dispatch({ type: 'CLICK_TREE_VIEW_DIALOG_SAVE_BUTTON', payload: false })
        dispatch({ type: 'TOGGLE_TREE_VIEW_SAVE', payload: !isTreeViewToggled })
      }
      else if (isTaskSucceed === 'failed') {
        console.error('Task failed')
      }
      else {
        runTaskStatusFunc(client, data, toastRef, counter + 1)
      }
    }
    if (data?.dal_add_task[0]?.return_code_locale) {
      runTaskStatusFunc(client, data, toastRef)
    }
  }

  const addRegionsAndRegionsLinks = async () => {
    const treeViewData = treeRef.current.treeData;
    const foundObject = findObjectById(treeViewData, selectedNodeId);
    const { firstParent, secondParent } = findFirstAndSecondParent(treeViewData, foundObject.instance_name)
    const generatedRegionsGuid = uuidv4()
    const regionsDefinitionValue = 'POLYGON ((' +
      drawnPolygonLatLngs.map(({ lng, lat }) => `${lng} ${lat}`).join(', ') +
      ', ' + drawnPolygonLatLngs[0].lng + ' ' + drawnPolygonLatLngs[0].lat + '))';

    const { data } = await client.query({
      query: BASE_ADD_REGIONS_AND_REGIONS_LINKS, variables: {
        v_regions_guid: generatedRegionsGuid,
        v_regions_name: drawnPolygonName,
        v_regions_types_guid: foundObject.instance_guid,
        v_parent_regions_guid: firstParent.instance_guid,
        v_parent_regions_types_guid: secondParent === null || secondParent?.instance_guid === null ? null : secondParent?.instance_guid,
        v_regions_definition: regionsDefinitionValue,
        v_sys_owners_guid: ownersValue,
      }
    })
    const runTaskStatusFunc = async (client, data, toastRef, counter = 0) => {
      if (counter >= 10) {
        return; // Exit recursion if counter reaches 10
      }
      let isTaskSucceed = await showTaskStatus(client, data, toastRef);
      if (isTaskSucceed === 'success') {
        const newNode = {
          instance_guid: generatedRegionsGuid,
          instance_name: drawnPolygonName,
          is_checked: true,
          regions_groups_regions_guid: uuidv4(),
          parent_regions_groups_regions_guid: foundObject.regions_groups_regions_guid === null ? uuidv4() : foundObject.regions_groups_regions_guid,
          number_of_children: false,
          type: 'Region'
        };

        treeRef.current.addNodes([newNode], selectedNodeId);
        setTreeData(treeRef.current.treeData)
        manageRegionsPolygonsTreeViewUpdate()
      }
      else if (isTaskSucceed === 'failed') {
        console.error('Task failed')
      }
      else {
        runTaskStatusFunc(client, data, toastRef, counter + 1)
      }
    }

    if (data?.dal_add_task[0]?.return_code_locale) {
      runTaskStatusFunc(client, data, toastRef)
    }
  }

  useEffect(() => {
    if (isTreeViewSaveButtonClicked) {
      addRegionsAndRegionsLinks()
    }

  }, [isTreeViewSaveButtonClicked])



  return (
    <div style={{ minHeight: '85.6vh' }}>
      <ToastComponent ref={toastRef} />
      <div>
        <TreeViewPopoverHeader
          treeRef={treeRef}
          treeDataObject={treeDataObject}
          treeViewNodeState={treeViewNodeState}
          setTreeData={setTreeData}
          manageRegionsPolygonsTreeViewUpdate={manageRegionsPolygonsTreeViewUpdate}
          setOwnersValue={setOwnersValue}
        />
      </div>
      <hr />
      <div style={{ overflowY: 'auto', maxHeight: 'calc(75.6vh - 10px)' }}>
        <TreeViewComponent
          autoCheck={false}
          showCheckBox={true}
          ref={treeRef}
          fields={{ dataSource: treeData, id: 'regions_groups_regions_guid', text: 'instance_name', child: 'child', parentID: 'parent_id', hasChildren: 'has_children', isChecked: 'is_checked' }}
          nodeChecked={handleNodesCheck}
          nodeClicked={handleNodeClick}
        />
      </div>
    </div>
  );
};

export default TreeViewPopover;