import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/react-hooks';
import { setContext } from '@apollo/client/link/context';
import Auth from './components/auth/Auth';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { GlobalTheme, theme } from './context/GlobalTheme';
import { useEffect, useState } from 'react';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        headers: {
            ...headers,
            authorization: user ? `Bearer ${user.access_token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
})

client.defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
};

const App = () => {
    const [activeTheme, setActiveTheme] = useState('default')

    return (
        <ApolloProvider client={client}>
            <GlobalTheme.Provider value={{
                ...theme,
                activeTheme: activeTheme,
                setActiveTheme: setActiveTheme
            }}>
                <Auth/>
            </GlobalTheme.Provider>
        </ApolloProvider>
    )
}

export default App