import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Viewer } from "@grapecity/activereports-react";
import { gql } from "@apollo/client";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';




const ReportPanel = ({ panel, panelJSON, reportQuery }) => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);

    const viewerRef = React.useRef();


    function editReport() {
        navigate(`/edit-report`, { state: { panelJSON: panelJSON } })
    }

    const openReport = async (query, report) => {
        setIsLoading(true);
        const data = await client.query({
            query: gql`
          ${query}
        `});
        report.DataSources[0].ConnectionProperties.ConnectString =
            "jsondata=" + JSON.stringify(data);
        setIsLoading(false);
        return report
    }

    useEffect(() => {
        async function showReport(reportViewer) {
            const report = await reportViewer
            viewerRef.current.Viewer.open(report);
        }
        const reportDefinition = JSON.parse(JSON.stringify(panelJSON.report_definition))
        const reportViewer = openReport(reportQuery, reportDefinition)
        if (reportViewer && panel.sys_panels_guid === refreshedPanelsGuid) {
            showReport(reportViewer);
        }

    }, [ isRefreshButtonClicked]);

    useEffect(() => {
        async function showReport(reportViewer) {
            const report = await reportViewer
            viewerRef.current.Viewer.open(report);
        }
        const reportDefinition = JSON.parse(JSON.stringify(panelJSON.report_definition))
        const reportViewer = openReport(reportQuery, reportDefinition)
        if (reportViewer) {
            showReport(reportViewer);
        }

    }, [panelJSON]);


    // if (isLoading) {
    //     return (<div style={{ height: 'calc(100% - 49px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //         <Player
    //             autoplay
    //             loop
    //             src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
    //             style={{ height: '20vh', width: '20vw' }}
    //         />
    //     </div>)
    // }

    return (
        <div id="viewer-host" style={{ height: 'calc(100% - 49px)' }}>
            <Viewer ref={viewerRef} />
        </div>
    )


}

export default ReportPanel