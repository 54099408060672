import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useForm, Controller } from "react-hook-form";
import { gql } from "@apollo/client";
import Input from './input'
import { getTaskStatus } from "../../apollo/queries";
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import { ActionButtonContext } from '../../context/ActionButtonContext';
import { SidebarContext } from './Main/context';
import { useSelector, useDispatch } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';


const Error = ({ children }) => <p style={{ color: "red" }}>{children}</p>;

const FormsQuery = ({ panel, panelJSON, formsQuery, queryName, entitiesId, l, animalsId }) => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const locale = localStorage.getItem('locale');
    const { handleSubmit, control, setValue, formState: { errors }, getValues } = useForm();
    const toastRef = React.useRef(null);
    const { actionButton, changeActionButton } = useContext(ActionButtonContext);
    const sidebarContext = useContext(SidebarContext);
    const [isLoading, setIsLoading] = useState(false);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);


    const _getFormsQuery = async () => {
        try {
            setIsLoading(true);
            const { data } = await client.query({ query: gql`${formsQuery}` })
            const responseData = data[queryName][0]
            const fields = []
            panelJSON.controls.map((c) => {
                const { name } = c;
                fields.push(name)
            })

            fields.forEach(field => {
                if (animalsId) {
                    setValue(`${animalsId.param.replace(/v_/g, "")}`, animalsId.param_value)
                }
                setValue(field, responseData?.[field])
            })
        } catch (err) {
            console.error(err);
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (panel.sys_panels_guid === refreshedPanelsGuid) {
            _getFormsQuery()
        }

    }, [isRefreshButtonClicked])

    useEffect(() => {
        _getFormsQuery()
    }, [panelJSON, sidebarContext?.onSubmit])


    const formInputs = panelJSON.form_definition.reduce((rows, formItem, index, array) => {
        const { required, label, name } = formItem;
        let { query } = formItem;
        if (query !== null) {
            query = query.replace('@v_locale', 'en').replace('\"49e2b2c9-1265-4ea4-bf86-3d9fb5f13aaf\"', entitiesId)
            query = query.substring(1, query.length - 1)
        }

        const classes = `col-${formItem.sizex}`;

        // Check if the current item has the same row value as the previous one
        if (index === 0 || formItem.row !== array[index - 1].row) {
            rows.push([]); // Start a new row
        }


        // Add the current item to the last row
        rows[rows.length - 1].push(
            <div key={index} className={classes}>
                <section className='dynamic-forms my-section'>
                    <label style={{ fontSize: '12px', display: formItem.hidden || formItem.type === 'geometry' ? 'none' : 'block', color: '#757575' }}>{label}</label>
                    <Controller
                        name={name}
                        control={control}
                        rules={{ required: required }}
                        defaultValue=""
                        render={({ field }) => (
                            <div style={{ display: formItem.hidden ? 'none' : 'block' }}>
                                <Input
                                    value={field.value}
                                    onChange={field.onChange}
                                    inputQuery={query}
                                    {...formItem}
                                />
                            </div>
                        )}
                    />
                    {errors[name] && <Error>{label} field is required</Error>}
                </section>
            </div>
        );

        return rows;
    }, []).map((row, rowIndex) => (
        <div key={rowIndex} className="row">
            {row}
        </div>
    ));

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    function replacePlaceholders(obj, data) {
        const replacedObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (typeof value === 'string' && value.startsWith('@')) {
                    const placeholder = value.substring(1);
                    if (placeholder === 'v_locale') {
                        replacedObj[key] = "en"; // Replace @v_locale with "en"
                    } else if (data.hasOwnProperty(placeholder)) {
                        replacedObj[key] = data[placeholder];
                    } else {
                        replacedObj[key] = value; // If placeholder not found, keep the original value
                    }
                } else {
                    replacedObj[key] = value;
                }
            }
        }
        return replacedObj;
    }

    const onSubmit = async (formData) => {
        const formDataCopy = { ...formData };

        // Get the user's time zone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Manually convert date fields to strings using the user's time zone
        Object.keys(formDataCopy).forEach(key => {
            if (formDataCopy[key] instanceof Date) {
                const date = formDataCopy[key];
                const formattedDate = date.toLocaleString('en-GB', { timeZone: userTimeZone });
                const parts = formattedDate.split(/[\s,]+/);
                const dateParts = parts[0].split('/');
                const timeParts = parts[1].split(':');

                // Construct a new date string in the desired format
                formDataCopy[key] = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timeParts[0]}:${timeParts[1]}:${timeParts[2]}.000Z`;
            }
        });

        // Now serialize the formDataCopy
        const serializedData = JSON.parse(JSON.stringify(formDataCopy, (key, value) => {
            if (typeof value === 'undefined') {
                return null;  // Replaces undefined values with null
            }
            return value;
        }));
        let JSONObject = actionButton.action_parameters
        delete JSONObject['action_order']
        const replacedArrayOfObjects = JSONObject.map(obj => replacePlaceholders(obj, serializedData));
        let actionParameters = JSON.stringify(replacedArrayOfObjects)
        actionParameters = actionParameters.replace("@v_locale", "en")
        actionParameters = actionParameters.replace(/"([^"]+)"\s*:/g, '$1:')
        actionParameters = actionParameters.replace(/\"null\"/g, null)
        actionParameters = actionParameters.replace(/\"nullutc\"/g, null)
        actionParameters = actionParameters.replace(/\"null_time_zone\"/g, null)
        actionParameters = actionParameters.replace(/\"@carry\"/g, null)
        actionParameters = actionParameters.replace(/\"@combined\"/g, null)
        actionParameters = actionParameters.replace(/action_order:1,/g, '')
        const { data } = await client.query({
            query: gql`
                            query myQuery{
                                dal_add_task(args: {
                                    v_task_name: "${panel.panel_name}",
                                    v_task_description: "${panel.panel_name}",
                                    v_task_params: ${actionParameters},
                                    v_tasks_guid: ${null},
                                    v_task_priority: ${10},
                                    v_tasks_types_guid: "${actionButton.action_value}"}){
                                        gql_results
                                        return_code
                                        return_code_locale
                                    }
                            }`});
        async function showTaskStatus() {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.dal_add_task[0]?.gql_results.tasks_guid }
            });

            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0]?.return_code_locale.message, 'success');
                return setTimeout(() => { navigate(-1) }, 1000);
            } else {
                if (status?.data?.dal_get_task_status[0].return_code_locale || status?.data?.dal_get_task_status[0].gql_results.task_status === "FAILED") {
                    showToast(status?.data?.dal_get_task_status[0]?.return_code_locale?.message || 'Task failed', 'danger');
                } else {
                    showTaskStatus(); // Call the function recursively to run the code again
                }
            }
        }

        if (data?.dal_add_task[0]?.return_code_locale) {
            showTaskStatus();
        }
    };

    useEffect(() => {
        if (actionButton) {
            handleFormSubmit();
        }
        return () => {
            changeActionButton(null); // Clear the form errors when the component unmounts
        };
    }, [actionButton]);




    const handleFormSubmit = async () => {
        const isValid = await handleSubmit(onSubmit)();
        if (isValid) {
            // Form is valid, continue with form submission
            const formData = getValues();
            onSubmit(formData);
        } else {
            console.log(errors)
        }
    };


    return (
        <div className="wrapper" id="forms" style={{ padding: '12px 20px', background: '#fff', overflow: 'auto', height: 'calc(100% - 41px)' }}>
            <ToastComponent ref={toastRef} />
            {
                isLoading ?
                    <div style={{ height: 'calc(100% - 49px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Player
                            autoplay
                            loop
                            src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                            style={{ height: '20vh', width: '20vw' }}
                        />
                    </div>
                    :
                    <form onSubmit={handleFormSubmit}>
                        <div className="row">
                            {/* render the form inputs */}
                            {formInputs}
                        </div>
                    </form>
            }
        </div>

    )


}

export default FormsQuery
