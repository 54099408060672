import { getTaskStatus } from '../apollo/queries';
import showToast from './showToastUtils';

async function showTaskStatus(client, data, toastRef) {
    const status = await client.query({
        query: getTaskStatus,
        variables: { tasks_guid: data?.dal_add_task[0]?.gql_results.tasks_guid }
    });

    if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
        showToast(toastRef, status?.data?.dal_get_task_status[0]?.return_code_locale.message, 'success');
        return 'success'
    } else {
        if (status?.data?.dal_get_task_status[0].return_code_locale || status?.data?.dal_get_task_status[0].gql_results.task_status === "FAILED") {
            showToast(toastRef, status?.data?.dal_get_task_status[0]?.return_code_locale?.message || 'Task failed', 'danger');
            return 'failed'
        } else {
            return 'smth wrong'
        }
    }
}

export default showTaskStatus;