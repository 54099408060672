import * as actionTypes from './manageViewsActions';

export const initialState = {
    isManageEditButtonCLicked: false,
    isManageSaveButtonCLicked: false,
    isManageViewsSaveAsDialogOpen: false,
    isManageVewsSaveAsDialogSaveButtonClicked: false,
    manageViewsViewName: null,
};

const manageViewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_MANAGE_VIEWS_EDIT_BUTTON:
            return { ...state, isManageEditButtonCLicked: action.payload };
        case actionTypes.TOGGLE_MANAGE_VIEWS_SAVE_BUTTON:
            return { ...state, isManageSaveButtonCLicked: action.payload };
        case actionTypes.TOGGLE_MANAGE_VIEWS_SAVE_AS_DIALOG:
            return { ...state, isManageViewsSaveAsDialogOpen: action.payload };
        case actionTypes.CLICK_MANAGE_VIEWS_SAVE_AS_DIALOG_SAVE_BUTTON:
            return { ...state, isManageVewsSaveAsDialogSaveButtonClicked: action.payload };
        case actionTypes.CHANGE_MANAGE_VIEWS_VIEW_NAME:
            return { ...state, manageViewsViewName: action.payload };

        default:
            return state;
    }
};

export default manageViewsReducer;
