import React, { useState, useEffect, useContext, memo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GridComponent as GridComponentEJ2, ColumnsDirective, ColumnDirective, Page, Inject, Filter, Sort, InfiniteScroll, VirtualScroll } from '@syncfusion/ej2-react-grids';
import { gql } from "@apollo/client";
import { SelectedRowContext } from '../../context/SelectedRowContext';
import { Tooltip } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import styled from 'styled-components';
import { GlobalTheme } from '../../context/GlobalTheme';
import { SidebarContext } from './Main/context';
import { Player } from '@lottiefiles/react-lottie-player';
import { useSelector, useDispatch } from 'react-redux';
import { getLocaleDateString } from '../../utils/getLocaleDateString';

const Wrapper = styled('div')`
  & #Grid_content_table {
    background: ${props => props.bg};
  }
  
  & td {
    color: ${props => props.textColor} !important;
  }
`;

const GridComponent = styled(GridComponentEJ2)`
  & .e-content {
    background: ${props => props.bg} !important;
  }
`;

const GridPanel = memo(({ panel, panelJSON, gridQuery, dynamicGridHeight }) => {
    const client = useApolloClient();
    const [gridList, setGridList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedRow, changeSelectedRow } = useContext(SelectedRowContext);
    const sidebarContext = useContext(SidebarContext);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);

    const isValidDateTime = (value) => {
        return typeof value === 'string' && !/^\d+$/.test(value) && moment(value, moment.ISO_8601, true).isValid();
    };

    const isDateOnly = (dateString) => {
        const date = moment(dateString, moment.ISO_8601, true);
        return date.isValid() && date.format('HH:mm:ss') === '00:00:00' && dateString.length === 10;
    };

    const formatData = (data) => {
        const dateFormat = getLocaleDateString();
        const dateTimeFormat = getLocaleDateString() + ', HH:mm:ss';

        return data.map(item => {
            const newItem = { ...item };
            Object.keys(newItem).forEach(key => {
                if (isValidDateTime(newItem[key])) {
                    newItem[key] =new Date(newItem[key]);
                }
            });
            return newItem;
        });
    };


    const _fetchData = async () => {
        setIsLoading(true);
        const { data } = await client.query({ query: gql`${gridQuery}` });
        const queryName = panelJSON.query_name;
        const formattedList = formatData(data?.[queryName]);
        setGridList(formattedList);
        setIsLoading(false);
    };


    useEffect(() => {
        if (panel.sys_panels_guid === refreshedPanelsGuid) {
            _fetchData();
        }

        return () => {
            changeSelectedRow(null);
        };

    }, [isRefreshButtonClicked]);

    useEffect(() => {
        _fetchData();
        return () => {
            changeSelectedRow(null);
        };
    }, [panelJSON, sidebarContext?.onSubmit])

    function rowSelected(g) {
        changeSelectedRow(g.data);
    }

    const globalTheme = useContext(GlobalTheme);

    return (
        <Wrapper
            className='control-section'
            bg={globalTheme[globalTheme.activeTheme]?.background}
            textColor={globalTheme[globalTheme.activeTheme]?.color}
            style={{ height: 'calc(100% - 49px)', width: '100%' }}
        >
            {
                isLoading ?
                    <div style={{ height: 'calc(100% - 49px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Player
                            autoplay
                            loop
                            src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                            style={{ height: '20vh', width: '20vw' }}
                        />
                    </div>
                    :
                    <GridComponent
                        bg={globalTheme[globalTheme.activeTheme]?.background}
                        id="Grid"
                        height={panel.panel_layout_json.sizeY > 6 ? panel.panel_layout_json.sizeY * dynamicGridHeight : panel.panel_layout_json.sizeY * (dynamicGridHeight - 8)}
                        enableVirtualization={true}
                        enableColumnVirtualiztion={true}
                        dataSource={gridList}
                        allowFiltering={true}
                        allowSorting={true}
                        filterSettings={{
                            type: 'Menu',
                        }}
                        rowSelected={rowSelected}
                    >
                        <ColumnsDirective>
                            {panelJSON && panelJSON.controls.map((control, index) => (
                                !control.hidden ?
                                    <ColumnDirective
                                        key={index}
                                        field={control.name}
                                        headerText={control.label}
                                        clipMode='EllipsisWithTooltip'
                                        width='150'
                                        type={
                                            control.type === 'datetime' ? 'datetime' :
                                            control.type === 'date' ? 'date' : undefined
                                        }
                                        format={control.type === 'datetime' ? getLocaleDateString() + ', HH:mm:ss':
                                            control.type === 'date' ? getLocaleDateString(): undefined
                                        }   
                                    ></ColumnDirective> : null
                            ))}
                        </ColumnsDirective>
                        <Inject services={[Tooltip, Filter, Sort, VirtualScroll]} />
                    </GridComponent>
            }
        </Wrapper>
    );
});

export default GridPanel;
