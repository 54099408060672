import * as React from "react";

export const PolygonDotsContext = React.createContext({
    clickSavePolygonDots: () => {},
    polygonDots: null,
});

export const PolygonDotsContextProvider = ({ children }) => {
    const [polygonDots, setPolygonDots] = React.useState(null);
    const polygonDotsValue = React.useMemo(
        () => ({
            clickSavePolygonDots: (key, value) => {
                setPolygonDots(prevState => ({
                    ...prevState,
                    [key]: value})
                );   
            },
            polygonDots,
        }),
        [polygonDots]
    );
    


    return(
        <PolygonDotsContext.Provider value={polygonDotsValue}>
            {children}
        </PolygonDotsContext.Provider>
    );
};

export const usePolygonDotsContext= () => React.useContext(PolygonDotsContext);